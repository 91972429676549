:root {
  --sidebar-width: 350px;
  --sidebar-compact-width: 170px;
}

/* Container starts here. */
.container {
  display: grid;
  grid-template-columns: auto 1fr;
}

/* The child(ren) can have their own overflow but the parent won't allow it. */
.container > * {
  overflow-y: auto;
  max-height: calc(100vh - var(--navbar-height));
}
/* Container ends here. */

/* Article starts here. */
.article {
  height: 100%;
  overflow-y: auto;
  position: relative;
  scrollbar-width: thin;
}
/* Article ends here. */

/* Sidebar starts here. */
.sidebar {
  composes: article;
  width: var(--sidebar-width);
  transition: width var(--transition-base) ease;
}

.sidebar[data-compact] {
  width: var(--sidebar-compact-width);
}
/* Sidebar ends here. */

/* Sidebar > Controller starts here. */
.sidebar .controller {
  padding: 0 0.25rem;
  position: fixed;
  top: 50%;
  left: calc(var(--sidebar-width) - 0.75rem);
  transform: translateY(-50%);
  transition: left var(--transition-base) ease;
}

.sidebar[data-compact] .controller {
  left: calc(var(--sidebar-compact-width) - 0.75rem);
}
/* Sidebar > Controller ends here. */

/* Sidebar > Display-controlled content starts here. */
.sidebar .hide-when-compact {
  display: block;
}

.sidebar[data-compact] .hide-when-compact {
  display: none !important;
}

.sidebar .stretch-when-compact,
.sidebar .shrink-when-compact {
  transition: width var(--transition-base) ease;
}

.sidebar[data-compact] .shrink-when-compact {
  width: 0 !important;
}

.sidebar[data-compact] .stretch-when-compact {
  width: 100%;
}
/* Sidebar > Display-controlled content ends here. */

/* Sidebar > Controller > Content starts here. */
.sidebar .controller > * {
  transform: rotate(180deg);
  transition: transform var(--transition-base) ease;
}

.sidebar[data-compact] .controller > * {
  transform: rotate(0deg);
}
/* Sidebar > Controller > Content ends here. */

/* Sidebar > List-Item starts here. */
.sidebar .thumbnail {
  min-width: 48.5px;
  max-width: 60px;
}

.sidebar .text {
  opacity: 1;
  transition: opacity var(--transition-base) ease;
}

.sidebar[data-compact] .text {
  opacity: 0;
}
/* Sidebar > List-Item ends here. */

/* Unorganized */
.sidebar[data-compact] .material-page {
  padding: 0 !important;
}
