:root {
  --editor-left-toolbar-width: 85px;
  --editor-top-toolbar-height: 55px;
}

/* Top Toolbar. */
.is-rte-tool {
  /*  it is kept so high to overcome the overlapping issue that block options were having. */
  z-index: 2000 !important;
  width: auto !important;
  display: block !important;
  box-shadow: none !important;
  top: var(--navbar-height) !important;
  left: var(--editor-left-toolbar-width) !important;
  border: 1px solid var(--bs-gray-300);
  border-top: 0;
  border-left: 0;
}

@media (min-width: 1200px) {
  .is-rte-tool {
    border: 0;
    left: 7rem !important;
    top: calc(var(--navbar-height) / 6) !important;
  }
}

/* Editor. */
.is-builder {
  margin: 0 !important;
  margin-bottom: 6rem !important;
  max-width: 100% !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  padding: 1.5rem;
  padding-top: calc(var(--editor-top-toolbar-height) + 1.5rem) !important;
  padding-left: calc(var(--editor-left-toolbar-width) + 1.5rem) !important;
}

@media (min-width: 992px) {
  .is-builder {
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 992px !important;
  }
}

@media (min-width: 1200px) {
  .is-builder {
    padding: 1.5rem !important;
  }
}

/* Left menu - expanded */
#divSnippetList {
  padding-top: var(--editor-top-toolbar-height);
}

@media (min-width: 1200px) {
  #divSnippetList {
    padding-top: 0;
  }
}

/* increasing bootstrap modal's z-index to get it on all other things */
body.modal-open
  > div[tabindex="-1"][style*="position: relative; z-index: 1050; display: block;"] {
  z-index: 3000 !important;
}
