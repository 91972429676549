.loader {
  width: 100%;
  height: 100vh;
  inset: 1;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white !important;
  z-index: 10000000000 !important;
}

.loader-inline {
  @compose loader;
  height: auto;
}
