/* BS override. */
:root {
  --bs-secondary-rgb: 5, 56, 107;
  --bs-secondary: rgb(var(--bs-secondary-rgb));

  /* Others. */
  --toastify-z-index: 100000;
  --color-accent: hsl(147, 64%, 61%);
}

.btn {
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  font-size: 0.85rem;
  height: auto !important;
  font-weight: 400 !important;
  text-align: center !important;
  text-decoration: none !important;
  cursor: pointer !important;
  padding: 0.375rem 0.75rem !important;
  border-radius: 0.25rem !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.btn-secondary {
  color: white !important;
  border: 1px solid var(--bs-secondary) !important;
  background-color: var(--bs-secondary) !important;
}

.btn-secondary:is(:hover, :focus) {
  border-color: rgb(3, 36, 70) !important;
  background-color: rgb(3, 36, 70) !important;
}

.btn-outline-secondary:is(:hover, :focus) {
  color: white !important;
  border-color: var(--bs-secondary) !important;
  background-color: var(--bs-secondary) !important;
}

/* Basic Utilities. */
.cursor-pointer {
  cursor: pointer !important;
}

.bg-blue {
  background-color: #3e6d9c !important;
}

.text-blue {
  color: #3e6d9c !important;
}

.z-10000 {
  z-index: 10000 !important;
}

.z-100000 {
  z-index: 100000 !important;
}

.z-1000000 {
  z-index: 1000000 !important;
}

.fs-5\.5 {
  font-size: 0.9rem;
}

.fs-7 {
  font-size: 0.75rem;
}

.fs-8 {
  font-size: 0.65rem;
}

/* The save + edit + delete buttons in the top right corner. */
/* .is-ui.ui_save_content {
  top: 5.75rem !important;
}

@media (min-width: 1168px) {
  .is-ui.ui_save_content {
    top: 1.25rem !important;
  }
} */

/* The contentbuilder's toolbar. */
#_cbhtml .is-elementrte-tool,
.is-ui .is-elementrte-tool {
  left: 10.5rem !important;
}
