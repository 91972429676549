:root {
  --thumbnail-height: 4.5rem;
}

.thumbnail {
  height: 100%;
  overflow: hidden;
  position: relative;
  min-height: var(--thumbnail-height);
}

.thumbnail-sm {
  height: 100%;
  overflow: hidden;
  position: relative;
  min-height: calc(var(--thumbnail-height) + 2rem);
}

.thumbnail-md {
  height: 100%;
  overflow: hidden;
  position: relative;
  min-height: calc(var(--thumbnail-height) + 3rem);
}

.thumbnail-lg {
  height: 100%;
  overflow: hidden;
  position: relative;
  min-height: calc(var(--thumbnail-height) + 4rem);
}

.thumbnail-xl {
  height: 100%;
  overflow: hidden;
  position: relative;
  min-height: calc(var(--thumbnail-height) + 5rem);
}

.thumbnail img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
