.pagination__item {
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 50%;
}

.pagination__button {
  padding: 0;
  width: 100%;
  height: 100%;
  color: gray;
  border-radius: 50%;
}

.pagination__button[data-active="true"] {
  color: var(--bs-white);
  background-color: var(--bs-success);
}
