:root {
  --transition-base: 0.25s;
  --transition-slow: 0.5s;
  --transition-slower: 0.75s;
  --transition-slowest: 1s;
}

/* Defaults start here. */
button,
.focusable {
  cursor: pointer;
  border: 1px solid var(--bs-gray-300);
}

:is(button, .focusable):is(:hover, :focus):not(:disabled) {
  outline: 2px solid var(--bs-primary);
}
/* Defaults end here. */

/* Directions start here. */
.dir-ltr {
  direction: ltr;
}

.dir-rtl {
  direction: rtl;
}
/* Directions end here. */

/* Text truncation starts here. */
.text-truncate-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-truncate-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
/* Text truncation ends here. */

/* BS colors start here. */
.bg-gray-100 {
  background-color: var(--bs-gray-100) !important;
}

.bg-gray-200 {
  background-color: var(--bs-gray-200) !important;
}

.bg-gray-300 {
  background-color: var(--bs-gray-300) !important;
}

.bg-gray-400 {
  background-color: var(--bs-gray-400) !important;
}

.bg-gray-500 {
  background-color: var(--bs-gray-500) !important;
}

.bg-gray-600 {
  background-color: var(--bs-gray-600) !important;
}

.bg-gray-700 {
  background-color: var(--bs-gray-700) !important;
}

.bg-gray-800 {
  background-color: var(--bs-gray-800) !important;
}

.bg-gray-900 {
  background-color: var(--bs-gray-900) !important;
}
/* BS colors end here. */

.text-disabled {
  color: var(--bs-gray-600);
}

/* Font sizes start here. */
.fs-5\.5 {
  font-size: 1.1rem !important;
}

.fs-7 {
  font-size: 0.9rem !important;
}

.fs-8 {
  font-size: 0.7rem !important;
}

.fs-9 {
  font-size: 0.6rem !important;
}

.fs-10 {
  font-size: 0.5rem !important;
}
/* Font sizes end here. */

/* Z-Indices start here. */
.z-0 {
  z-index: 0 !important;
}

.z-10 {
  z-index: 10 !important;
}

.z-20 {
  z-index: 20 !important;
}

.z-30 {
  z-index: 30 !important;
}

.z-40 {
  z-index: 40 !important;
}

.z-50 {
  z-index: 50 !important;
}
/* Z-Indices end here. */

/* React-Toastify starts here. */
.Toastify__toast {
  /* padding: 0; */
  --toastify-toast-min-height: 0;
}

.Toastify__toast-body {
  /* padding: 0; */
}
/* React-Toastify ends here. */

/* Object starts here. */
.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-center {
  object-position: center;
}

.object-top {
  object-position: top;
}

.object-end {
  object-position: right;
}

.object-bottom {
  object-position: bottom;
}

.object-start {
  object-position: left;
}
/* Object ends here. */
