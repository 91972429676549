:root {
  --navbar-height: 5rem;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--navbar-height);
}

.content {
  margin-top: var(--navbar-height);
  height: calc(100vh - var(--navbar-height));
}
